.referral-top-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.referral-search-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 30%;
}
