.fc {
  font-family: 'Roboto', sans-serif;
}

.fc-event {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-event .fc-icon {
  margin-right: 4px;
}

.fc-event .material-icons {
  vertical-align: middle;
  margin-right: 4px;
}

.rpm-event {
  background-color: transparent;
  color: gray;
  border: none;
}

.other-event {
  background-color: transparent;
  color: #1a657a;
  border: none;
}

.fc-event a {
  background: transparent;
}

.event-title.rpm {
  color: grey;
  background-color: transparent;
}

.event-title.other {
  color: #1a657a;
}

.event-details {
  width: 250px;
  border-left: 1px solid #ccc;
}

.event-list {
  width: 250px;
  border-left: 1px solid #ccc;
}

.fc-daygrid-event-harness a:hover {
  cursor: pointer;
}

.fc-daygrid-event-harness a {
  background-color: transparent;
  border: none;
}

.event-list li:hover {
  cursor: pointer;
  border: #1a657a 1px solid;
}

.fc .fc-list-event-graphic,
.fc .fc-list-event-time {
  display: none;
}

.fc-list-table .fc-list-event-title {
  width: 100%;
}

.fc-list-table .fc-list-event-title:hover {
  cursor: pointer;
}

.fc-event-title {
  display: flex;
  align-items: center;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(138 130 130 / 23%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #1a657a;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fc-popover {
  max-height: 300px;
  overflow-y: auto;
}

.fc-more-popover .fc-day {
  max-height: 300px;
  overflow-y: auto;
}

.claims .detail-box {
  margin-top: 0px;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
}

.claim-requests.side-panel {
  margin-top: 0px;
  width: calc(60%);
  background-color: #fafafa;
  height: 100vh;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  border-left: 1px solid #777;
}
