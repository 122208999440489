.medication-form-medication-section div {
  width: 35em;
}

.medication-form-medication-section input {
  width: 25em;
}

.medication-form-medication-section p {
  margin-bottom: 10px;
}
