.condition-form-condition-section div {
  width: 25em;
}

.condition-form-condition-section input {
  width: 25em;
}

.condition-form-condition-section p {
  margin-bottom: 10px;
}

.condition-form-select-add {
  width: 30em;
}

.conditions-section table {
  margin-bottom: 2em;
}

.conditions-section > p {
  font-size: 14px;
}
