.claim-sync-requests-section {
  margin-top: 0;
  background-color: #fafafa;
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 100vh;
}

.claim-sync-requests-section .header {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 1em;
}

.claim-sync-requests-section .header p {
  font-size: 1.1em;
}

.claim-sync-requests-section .header button {
  text-transform: capitalize;
  width: 10em;
}

.claim-sync-requests-section .table-wrapper {
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
}
