.audit-logs-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 10px;
}

.audit-logs-header p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.audit-logs-header button {
  text-transform: capitalize;
  width: 10em;
}

.audit-logs-section td {
  padding: 7px 16px;
  font-size: 12px;
}

.audit-logs-section tbody p {
  font-size: 12px;
}

.audit-logs-section th {
  font-size: 12px;
  padding-bottom: 10px;
}

.audit-logs-select div > div {
  padding: 6px 14px;
}
