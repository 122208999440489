.laboratory-tests-header {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 1em;
}

.laboratory-tests-header p {
  font-size: 1rem;
}

.laboratory-tests-header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 1em;
}

.laboratory-tests-header button {
  text-transform: capitalize;
  width: 13em;
}

.laboratory-tests-section td {
  padding: 7px 16px;
  font-size: 12px;
}

.laboratory-tests-section tbody p {
  font-size: 12px;
}

.laboratory-tests-section th {
  font-size: 12px;
  padding-bottom: 10px;
}
