#actions-menu-button {
  font-size: 12px;
  width: 86px;
  min-width: 80px;
  border-radius: 4px;
  height: 2.5em;
  border-color: #1a667a;
  color: #1a667a;
  padding-right: 10px;
}

#actions-menu-button span {
  margin: 2px;
}

#actions-menu li {
  font-size: 14px;
}

#actions-menu li:active {
  background-color: #1a677a7b;
}

#actions-menu svg {
  margin-right: 1em;
}

#actions-menu path {
  color: #404040;
}

#actions-menu .MuiPaper-root {
  border-radius: 6px;
  margin-top: 5px;
  box-shadow:
    rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

#actions-menu .MuiMenu-list {
  padding: 4px 0;
}
