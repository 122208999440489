.laboratory-test-form-laboratory-test-section div {
  width: 35em;
}

.laboratory-test-form-laboratory-test-section input {
  width: 25em;
}

.laboratory-test-form-laboratory-test-section p {
  margin-bottom: 10px;
}
