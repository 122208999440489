.patient-condition-section p {
  margin-bottom: 8px;
}

.patient-condition-section div {
  width: 15rem;
}

.patient-condition-section {
  display: flex;
  gap: 2rem;
}

.patient-condition-section-group {
  display: flex;
  flex-direction: column;
}

.patient-conditions-header {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 1em;
}

.patient-conditions-header p {
  font-size: 1rem;
}

.patient-conditions-header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 1em;
}

.patient-conditions-header button {
  text-transform: capitalize;
  width: 10em;
}

.confirmation-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-modal p,
.confirmation-modal h2,
.confirmation-modal h4 {
  font-size: 14px;
  font-weight: 400;
}

.confirmation-modal-container {
  width: 30em;
  height: 11em;
  padding: 2rem 2rem 1rem;
  background-color: white;
  border-radius: 5px;
}

button.confirmation-modal-button {
  width: 8em;
  height: 3em;
  text-transform: capitalize;
  border-color: #1a667a;
}

button.confirmation-modal-button {
  text-transform: capitalize;
  width: 8em;
  height: 3em;
  border-color: #1a667a;
}
