.conditions-header {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 1em;
}

.conditions-header p {
  font-size: 1rem;
}

.conditions-header button {
  text-transform: capitalize;
  width: 10em;
}

.conditions-section td {
  padding: 7px 16px;
  font-size: 12px;
}

.conditions-section tbody p {
  font-size: 12px;
}

.conditions-section th {
  font-size: 12px;
  padding-bottom: 10px;
}
