.tab-section-form-field {
  margin-bottom: 1em;
}

.tab-section {
  box-shadow: 0 1px 2px 0px #00000066;
  border-radius: 10px;
  margin-top: 1.5rem;
  background-color: white;
  padding: 24px;
}

.tab-section input {
  font-size: 13px;
}

.tab-section p {
  font-size: 12px;
}

.tab-section-action-bar p {
  font-size: 13px;
}

.tab-section-action-bar button {
  font-size: 12px;
}

.tab-section td {
  padding: 7px 16px;
  font-size: 12px;
}

.tab-section tbody p {
  font-size: 12px;
}

.tab-section th {
  font-size: 12px;
  padding-bottom: 10px;
}

.tab-section label {
  font-size: 13px;
}

.tab-section-table button {
  font-size: 12px;
}
